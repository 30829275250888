import * as React from 'react';
import { Link } from 'react-router-dom';

import ROUTES from 'platform/constants/routes';
import environment from 'platform/services/environment';
import { byRoute } from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import Carousel from './components/carousel';
import ContactUs from './components/contact-us';
import ForPartners from './components/for-partners';
import Settings from 'platform/services/settings';
import SuccessModal from 'components/success-modal';

import './style.scss';
import { Shared } from 'modules';
import News from './components/news';
import ChildrenProducts from "./components/children-products";
import CosmeticsProducts from "./components/cosmetics-products";
import SeasonalOffers from "./components/seasonal-offers";
import MedicalProducts from "./components/medical-products";
import OthersProducts from './components/other-products';
import DiscountedProducts from './components/discounted-products';
import {Helmet} from "react-helmet";
import {helmetJsonLdProp} from "react-schemaorg";
import {Person} from "schema-dts";
import Categories from "./components/categories";
import Connection from "../../../platform/services/connection";
const pageChangeListener = 'productlistpage';

interface IState {
    authModalOpen: boolean;
    orderSuccessModalOpen: boolean;
}



@byRoute(ROUTES.MN)
class Home extends HelperComponent<{}, IState> {

    public state: IState = {
        authModalOpen: false,
        orderSuccessModalOpen: false
    };

    // shat romantik grdon
    // if(this.armLeng === this.armLeng) {
    //   document.body.classList.add('armenianFont');
    // }else {
    //   document.body.classList.remove('armenianFont');
    // }

    
    

    private toggleAuthModal = () => {
        const { authModalOpen } = this.state;
        this.safeSetState({ successModalOpen: false, authModalOpen: !authModalOpen });
    };

    private Change = () => {
        Connection.AbortAll();
        window.dispatchEvent(new CustomEvent(pageChangeListener, {detail: 1}));
    }

    private closeOrderSuccessModal = () => this.safeSetState({ orderSuccessModalOpen: false });

    public render() {
        const { authModalOpen, orderSuccessModalOpen } = this.state;

        return (

            <section className="P-home-page">
                <Helmet
                    script={[
                        helmetJsonLdProp<Person>({
                            "@context": "https://schema.org",
                            "@type": "Person",
                            name: "Grace Hopper",
                            alternateName: "Grace Brewster Murray Hopper",
                            alumniOf: {
                                "@type": "CollegeOrUniversity",
                                name: ["Yale University", "Vassar College"],
                            },
                            knowsAbout: ["Compilers", "Computer Science"],
                        }),
                    ]}
                />
                <Carousel />
                {/*Categoryyy*/}
                {/*<Categories onChange={this.Change}/>*/}
                {/*///////////////////////*/}

                <DiscountedProducts />
                <SeasonalOffers />
                <ChildrenProducts />
                <CosmeticsProducts />
                <MedicalProducts />
								<OthersProducts />
                {environment.WHOLESALE ? <ForPartners /> : <News />}
                <ContactUs />

                {orderSuccessModalOpen && <SuccessModal onClose={this.closeOrderSuccessModal}>
                    {Settings.guest ? <>
                        <h3>{Settings.translations.guest_order_success}</h3>
                        <button id={'sign_up'} className="G-main-button G-normal-link G-mt-30 P-register-button"
                            onClick={this.toggleAuthModal}>{Settings.translations.sign_up}</button>
                    </> : <>
                        <h3>{Settings.translations.order_success}</h3>
                        <Link className="G-main-button G-normal-link G-mt-30"
                            to={ROUTES.PROFILE.ORDERS.MAIN}>{Settings.translations.order_history}</Link>
                    </>}
                </SuccessModal>}

                {authModalOpen && <Shared.Auth onClose={this.toggleAuthModal} />}
            </section>
        );
    }
}

export default Home;
