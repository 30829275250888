import * as React from "react";
import "./style.scss";
import Settings from "../../platform/services/settings";
import ClickOutside from "../click-outside";
import { getFullLanguageCode } from "../../platform/services/helper";
import { northEastArmenia, southWestArmenia } from "../../platform/constants";
import { useEffect, useState } from "react";
import {IYandexPlace} from "../../platform/constants/interfaces";

interface AddressItem {
    title: {
        text: string;
    };
    position?: [number, number];
}

interface IProps {
    placeholder?: string;
    className?: string;
    onChange?(value: string): void;
    onPlaceSelect?(item: IYandexPlace): void;
    defaultValue?: string;
}

const Addresses: React.FC<IProps> = React.memo(({ defaultValue, placeholder, onChange, onPlaceSelect, className }) => {
    const [addressesList, setAddressesList] = useState<AddressItem[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(defaultValue || '');
    const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if (defaultValue !== value) {
            setValue(defaultValue || '');
        }
    }, [defaultValue]);

    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    const toggleState = (isOpenBool: boolean) => setIsOpen(isOpenBool);

    const chooseItem = async (item: AddressItem) => {
        setValue(item.title.text);
        setIsOpen(false);
        const ymaps = window.ymaps;
        if (onPlaceSelect && window && ymaps) {
            const geocodeResponse = await ymaps.geocode(item.title.text);
            const place: IYandexPlace = {
                name: item.title.text,
                position: geocodeResponse?.geoObjects?.get(0)?.geometry?.getCoordinates(),
            };
            onPlaceSelect(place); // Pass IYandexPlace object
        }
    };

    const Options = () => (
        <ul className="P-select-body">
            {addressesList.length > 0 && addressesList.map((item, index) => (
                <li
                    key={index}
                    onClick={() => chooseItem(item)}
                    title={item?.title?.text}
                >
                    {item?.title?.text}
                </li>
            ))}
        </ul>
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setValue(newValue);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const id = setTimeout(async () => {
            try {
                const url = `https://suggest-maps.yandex.ru/v1/suggest?apikey=${Settings.suggestAPIKey}&types=geo&text=${encodeURIComponent(newValue)}&lang=${getFullLanguageCode(Settings.language)}&results=6&origin=jsapi2Geocoder&print_address=1&bbox=${southWestArmenia[1]},${southWestArmenia[0]},${northEastArmenia[1]},${northEastArmenia[0]}&strict_bounds=0`;
                const res = await fetch(url, {
                    method: 'GET',
                });

                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }

                const data = await res.json();
                setAddressesList(data?.results || []);
                setIsOpen((data?.results?.length || 0) > 0);

                if (onChange) {
                    onChange(newValue); // Pass the new value, not the event
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }, 1000);

        setTimeoutId(id);
    };

    return (
        <ClickOutside className={`G-main-form-field ${className}`} onClickOutside={() => toggleState(false)}>
            <div className={`P-select`}>
                <input
                    value={value}
                    onChange={handleChange}
                    className="G-main-input"
                    placeholder={placeholder}
                />
                {isOpen && <Options />}
            </div>
        </ClickOutside>
    );
});

export default Addresses;
