import * as React from 'react';
import * as JsBarcode from 'jsbarcode';

import HelperComponent from 'platform/classes/helper-component';
import { byPrivateRoute } from 'platform/decorators/routes';
import ROUTES from 'platform/constants/routes';
import Layout from '../../components/layout';
import Settings from 'platform/services/settings';
import Table from 'components/table';
import Storage from 'platform/services/storage';
import {
  IBonusCardDetailsWithHistoryResponseModel,
  IBonusCardHistoryItemResponseModel,
  IBonusCardHistoryResponseModel
} from 'platform/api/bonusCard/models/response';
import { formatDate, formatPrice } from 'platform/services/helper';
import Pagination from 'components/pagination';
import { BonusStatusEnum } from 'platform/api/bonusCard/constants/enums';
import BonusCardController from 'platform/api/bonusCard';
import { onlyForUsers } from 'platform/guards/routes';

import CardImage from 'assets/images/card.png';
import BonusCardCoin from 'assets/images/coin.png';

import './style.scss';
import EmptyState from "../../../../../components/empty-state";
import * as animationData from "../../../../../assets/animations/EmptyOrderList.json";
import * as loadingData from 'assets/animations/loading.json';
import {Link} from "react-router-dom";
import Tabs from './Tabs/index'
import HistoryExt from "./HistoryExt/index";

interface IState {
  data?: IBonusCardDetailsWithHistoryResponseModel;
  isLoading: boolean;
  smallMedia: boolean;
  itemsPerPage: number;
};

@byPrivateRoute(ROUTES.PROFILE.BONUS_CARD, [onlyForUsers])
class BonusCard extends HelperComponent<IState, {}> {

  public state: IState = {
    isLoading: true,
    smallMedia: false,
    itemsPerPage: 15,
  };
  public componentDidMount() {
    if (window.innerWidth <= 900) {
      this.safeSetState({smallMedia: true})
    }
  }
  private columnConfig = [
    {
      name: Settings.translations.order_number,
      cell: (row: IBonusCardHistoryItemResponseModel) => `#${row.orderId}`,
    },
    {
      name: Settings.translations.date,
      cell: (row: IBonusCardHistoryItemResponseModel) => formatDate(row.date),
    },
    {
      name: Settings.translations.price,
      cell: (row: IBonusCardHistoryItemResponseModel) => formatPrice(row.amount),
    },
    {
      name: Settings.translations.bonus,
      cell: (row: IBonusCardHistoryItemResponseModel) => row.status === BonusStatusEnum.Recieved
          ? <span className="G-clr-main">+{row.bonus}</span>
          : <span className="G-clr-orange">-{row.bonus}</span>,
    },
  ];

  private fetchData = async (pageNumber: number,itemsPerPage: number) => {
    const body = {
      pageNumber,
      pageSize: itemsPerPage,
    };

    const result = await BonusCardController.GetDetailsWithHistory(body);
    this.safeSetState({ data: result.data, isLoading: false }, () => {
      JsBarcode('#barcode', result.data.bonusCardDetails.cardNumber, { format: 'code128', displayValue: false });
    });
    return result.data && result.data.bonusHistoryGroupedByDate;
  }



  public render() {
    const { data, isLoading, smallMedia} = this.state;
    const datesList = data && data.bonusHistoryGroupedByDate && data.bonusHistoryGroupedByDate.list;
    const lengthPrice=200;
    return (
        <Layout>
          <div>
            <Tabs tabs={
              [
                {
                  title: Settings.translations.bonus_card,
                  content: <>
                    <h2 className="G-clr-main G-mb-30">{Settings.translations.bonus_card}</h2>
                    {data && <div className="G-flex P-profile-bonus-card">
                      <div className="P-card-details">
                        <div className="P-card">
                          <img src={CardImage} alt="card"/>
                          <span className="G-clr-orange P-bonus-amount" style={{left: `${lengthPrice}px`}}>
                  {data.bonusCardDetails.amount}
                </span>
                          <img className="P-bonus-coin" src={BonusCardCoin} alt="coin"/>
                        </div>
                        <div className="P-barcode">
                          <h3>{Storage.profile.firstName} {Storage.profile.lastName}</h3>
                          <img id="barcode" alt={'barcode'}/>
                          <h4>{data.bonusCardDetails.cardNumber}</h4>
                        </div>
                      </div>
                      {datesList && !!datesList.length && datesList.map(item =>
                          <div key={item.date}>
                            {!smallMedia ?
                                <div key={item.date} className="P-date-block">
                                  <h3>{formatDate(item.date, false)}</h3>
                                  <Table<IBonusCardHistoryItemResponseModel>
                                      redirectUrl={row => ROUTES.PROFILE.ORDERS.DETAILS.replace(':id', row.orderId)}
                                      columnConfig={this.columnConfig}
                                      isBonusHistory={false}
                                      data={item.bonusHistory}
                                  />
                                </div> :
                                <>
                                  <div key={item.date} className="P-date-block">
                                    <h3>{formatDate(item.date, false)}</h3>
                                  </div>
                                  <div className={'bonus-item'}>
                                    {item.bonusHistory.map(row =>
                                            <>
                                              <Link key={row.orderId}
                                                    to={ROUTES.PROFILE.ORDERS.DETAILS.replace(':id', row.orderId)}>
                                                <span className={`G-text-bold`}>#{row.orderId}</span>
                                                <span className={`G-clr-gray`}>{formatPrice(row.amount)}</span>
                                                <span
                                                    className={`G-text-bold ${row.status === 1 ? ' G-clr-main' : ' G-clr-orange'}`}>
                                  {row.status === 1 ? '+' : '-'}
                                                  {row.bonus} &nbsp;
                                                  {Settings.translations.point}
                                </span>
                                              </Link>
                                            </>
                                    )}
                                  </div>
                                </>
                            }
                          </div>
                      )}
                      {(!datesList || !datesList.length) &&
                          <EmptyState animationData={isLoading ? loadingData : animationData} height={175}
                                      text={isLoading ? '' : Settings.translations.bonus_card_empty}/>}
                    </div>}
                    <Pagination<IBonusCardHistoryResponseModel> fetchData={this.fetchData} showItemsPerPage={true}/>
                  </>
                },
                {
                  title: Settings.translations.bonus_history,
                  content: <HistoryExt/>
                }
              ]
            }/>
          </div>
        </Layout>
    );
  }
}

export default BonusCard;